import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames/bind";
import Loading from "src/components/Loading";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import styles from "src/features/PositionAndHistory/styles/Order.module.scss";
import {
  CloseAllPosition,
  ClosePositionDialog,
} from "src/features/PositionAndHistory/dialog/ClosePositionDialog/index";
import { getPosition, IPosition, getAllPosition } from "src/services/position";
import BigNumber from "bignumber.js";
import EntierPositionDialog from "./dialog/EntierPositionDialog/index";
// import { editIcon2, noData, noDataDark } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import {
  formatPrice,
  formatRoundDown,
  getValueClassName,
  isNumber,
} from "src/helpers/numberFormatter";
import { Instrument } from "src/services/instrument";
import { CInput } from "src/components/cores/Input";
import { Box } from "@material-ui/core";
import { Pagination } from "@mui/material";
import { RECORDS_PER_PAGE, TypeTrade } from "src/constants/common.constants";
import { OrderSide, OrderType } from "./constant";
import { getPositionDirection, getSymbolName } from "./helper";
import AdjustMarginDialog from "./dialog/AdjustMarginDialog/index";
import { getNumberRegex } from "src/features/Market/components/OrderForm";
import {
  formatValidAmount,
  formatValidPrice,
} from "src/features/Market/MarketHelper";
import { getCookieStorage, setNewOneCookieStorage } from "src/helpers/storage";
import PositionCalculator from "src/helpers/PositionCalculator";
import { formatPercent } from "src/helpers/numberFormatter";
import axiosInstance from "src/helpers/config";
import { noti } from "src/hooks/useNoti";
import { IOrder } from "src/interfaces/order";
import TooltipText from "src/components/cores/TooltipText";
import TooltipNumber, { DECIMAL_TWO } from "src/components/cores/Tooltip";
import useScreen, { useWindowSize } from "src/hooks/useScreen";
import { getContractType } from "./helper";
import { useTranslation } from "react-i18next";
import { THEME_MODE } from "src/interfaces/theme";
import TextNumber from "src/components/cores/TextNumber";
import { BsInboxesFill, BsFillPencilFill } from "react-icons/bs";

export const handleDirect = (type: string) => {
  const domain = process.env.REACT_APP_DOMAIN_SPOT;
  if (type === "login") {
    window.location.replace(`${domain}/login?destination=%2F`);
    // setNewOneCookieStorage(
    //   "access_token",
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI3IiwianRpIjoiYjU4MjllMDkyZWNlMGZlMDBlODA4NjQ2OTkwNzQ0Y2NkMTFjNzEwZmU4NTlhMzQxYTMxMTlmYTY2MTAwMDA1NjZlN2FjOTliMTM4YTkyN2YiLCJpYXQiOjE3MjY2MzE3NDIuNDQ4NjQ5LCJuYmYiOjE3MjY2MzE3NDIuNDQ4NjUxLCJleHAiOjE3MjY3MTgxNDIuNDM0MTE4LCJzdWIiOiI1Iiwic2NvcGVzIjpbIioiXX0.hutHE67Z041otlPs8sVoCFtsLnJoptIOV8gUEu5M4JXoqI-QuoolemxhzPWn1CLN83ez6vnpFKLRGPg114pc8QOFF1c0iIoJmPzU8f9JRbWaXq0gokZULJFjbrI0uSg3gtzulu0dhpoT2_qYujPlEBXUGLiWtPmr7pfhSUBv4gIuRSMpugpQJ2c7wZKwxXlxTztdBie5Zef49_IN6AMCO3RJnOTVEKAG_SDxCvAbBb03pj2NAFE4ZMOQ29Zc4FJ1bOsHHb1v1B8RGnBTDieeT545ELwaSxFDTtfpf0oq8Kd3lYyWfWT4XnibtjMDwiMce91_2zyHlpAwtkoO-OVpwn19gzxzDmrskVmR46DqLTW8v_cshHy4jqLgZYnHsqbLQMkSwli4rHF7EHRJBCUwlvE17cId9IsOM4uDJMNSiEUmhp-sGy7uHiEkvmgV4Wnaj_EEWNeSGnmpHCSDHGDbf3_tJFBsS9mB22q0SdFHF-Tjdl3xuqe6-vuG6mtJmqTS-QFfFcgVWmQ2KbYXFLJUojNrspanfO-JQ062gCqBM7v3WhgT2eLRgKVuFI0lL9zxTFAVt8J5l9VsvY5ji64hwDh6QWb1m5JhBg5MvbtGfx4KtiA3bhK6CZtfnX28KMPAAuRdHd42tTcNr6H7wIaTBTflFuPLNhEZ1XLTXl_aFWA"
    // );
    // setNewOneCookieStorage("isExpired", false);
  } else {
    window.location.replace(`${domain}/register`);
    // alert("set register later");
  }
};

const cx = classNames.bind(styles);

export interface IPositionItem extends IPosition {
  limitPrice: string;
  positionSize: string;
}

interface InputNumberProps {
  defaultValue: string;
  position: IPositionItem;
  type: "price" | "quantity";
  onChange?: (value: string, position: IPositionItem) => void;
}

const InputNumber = (props: InputNumberProps) => {
  const { defaultValue, position, type, onChange } = props;
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState<string>(defaultValue);
  const [error, setError] = useState<boolean>(false);
  const instruments: Instrument[] = useAppSelector(
    (state) => state.instrument.instrument
  );
  const currentInstrument = instruments?.find(
    (instrument) => instrument?.symbol === position?.symbol
  );
  const tradingRulesCache = useAppSelector(
    (state) => state.masterdataFuture.tradingRulesCache
  );
  const curTradingRule = tradingRulesCache?.filter(
    (item) => item?.symbol === position?.symbol
  );

  const decimalPrice =
    Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;
  const priceRegex = getNumberRegex(decimalPrice);
  const decimalAmount =
    Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;
  const amountRegex = getNumberRegex(decimalAmount);

  const contractType = getContractType();
  const isCoinM = contractType === TypeTrade.COIN_M;

  useEffect(() => {
    if (isNumber(defaultValue)) {
      setInputValue(defaultValue);
    } else {
      setInputValue("");
    }
  }, [defaultValue]);

  useEffect(() => {
    if (!Number(inputValue)) {
      setError(true);
    } else if (
      type === "quantity" &&
      new BigNumber(inputValue).gt(
        new BigNumber(position.currentQty)
          .abs()
          .toFixed(decimalAmount, BigNumber.ROUND_DOWN)
      )
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [inputValue]);

  const handleChangeValue = (value: string) => {
    if (!isNumber(value) && value) {
      return;
    }

    value = value?.trim().replace(/^0+\B/, "");
    if (isCoinM && type === "quantity") value = value.replace(".", "");
    const s = value?.split(".");
    const beforeDecimal = s[0];
    if (beforeDecimal.length > 8) {
      return;
    }

    if (type === "price") {
      if (priceRegex.test(value)) {
        setInputValue(value);
        onChange?.(value, position);
      } else {
        if (!currentInstrument || (s[1] && s[1].length > decimalPrice)) return;

        const price = formatValidPrice(value, currentInstrument);
        setInputValue(price);
        onChange?.(price, position);
      }
    } else if (type === "quantity") {
      if (amountRegex.test(value)) {
        setInputValue(value);
        onChange?.(value, position);
      } else {
        if (!currentInstrument || (s[1] && s[1].length > decimalAmount)) return;
        const amount = formatValidAmount(value, currentInstrument);
        setInputValue(amount);
        onChange?.(amount, position);
      }
    }
  };

  return (
    <CInput
      classNamePrefix={cx("input-positions", error && "error-input")}
      type="text"
      placeholder=""
      size="xs"
      outline={false}
      valueHandSet={inputValue}
      onChange={handleChangeValue}
    />
  );
};

const PositionRecord = (props: any) => {
  const { hideOtherPairs } = props;
  const dispatch = useAppDispatch();
  const userSetting = useAppSelector((state) => state.account.userSetting);
  const instruments: Instrument[] = useAppSelector(
    (state) => state.instrument.instrument
  );
  const currentInstrument = useAppSelector(
    (state) => state.instrument.currentInstrument
  );
  const tickers = useAppSelector((state) => state.ticker.tickers);
  // console.log("tickers ==>", tickers);
  const listPosition = useAppSelector((state) => state.position.positions);
  const allPosition = useAppSelector((state) => state.position.allPosition);
  const account = useAppSelector((state) => state.account.account);
  const tradingRulesCache = useAppSelector(
    (state) => state.masterdataFuture.tradingRulesCache
  );
  // console.log("tradingRulesCache", tradingRulesCache);
  const allOrders = useAppSelector((state) => state.order.allOrders);

  const [rows, setRows] = useState<IPositionItem[]>([]);
  const [openClosePositionDialog, setOpenClosePositionDialog] =
    useState<boolean>(false);
  const [openCloseAllPositionDialog, setOpenCloseAllPositionDialog] =
    useState<boolean>(false);
  const [openEntierPositionDialog, setOpenEntierPositionDialog] =
    useState<boolean>(false);
  const [openAdjustMarginDialog, setOpenAdjustMarginDialog] =
    useState<boolean>(false);
  const [listPositionId, setListPositionId] = useState<number[]>([]);

  const [loading, setLoading] = useState(false);
  const loadingInstrument = useAppSelector((state) => state.instrument.loading);
  const loadingTicker = useAppSelector((state) => state.ticker.loading);
  const loadingTradingRules = useAppSelector(
    (state) => state.masterdataFuture.loading
  );
  const listPositionRef = useRef<any>([]);
  const changeCheckboxRef = useRef(hideOtherPairs);
  const changeInstrumentRef = useRef(currentInstrument.symbol);

  const ticker = useAppSelector((state) =>
    state.ticker.tickers?.find(
      (ticker) => ticker.symbol === state.instrument.currentInstrument.symbol
    )
  );

  const [currentPosition, setCurrentPosition] = useState<IPositionItem>();
  const [closePostionData, setClosePostionData] =
    useState<{ type: OrderType.market | OrderType.limit; price: string }>();

  const currentPage = useAppSelector((state) => state.position.currentPage);
  const totalPage = useAppSelector((state) => state.position.totalPage);
  const token = getCookieStorage("access_token");
  const isExpired = getCookieStorage("isExpired");
  const isAuthenticated =
    isExpired === "false" && token && token !== "undefined";
  //  &&
  // account &&
  // account[0]?.id;
  const contractType = getContractType();
  const isCoinM = contractType === TypeTrade.COIN_M;

  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const size = useWindowSize();
  const { isMobile, isTablet } = useScreen();
  const { t } = useTranslation("common");
  const fetchData = async (page: number) => {
    const payload: any = { page: page, size: RECORDS_PER_PAGE, contractType };
    if (hideOtherPairs) {
      payload.symbol = currentInstrument.symbol;
    }
    await dispatch(getPosition(payload));
    // await dispatch(getAllPosition(contractType));
  };

  useEffect(() => {
    setLoading(loadingInstrument || loadingTicker || loadingTradingRules);
  }, [loadingInstrument, loadingTicker, loadingTradingRules]);

  useEffect(() => {
    if (currentPage && isAuthenticated) {
      // if (changeCheckboxRef.current !== hideOtherPairs || changeInstrumentRef.current !== currentInstrument.symbol) {
      //   fetchData(1);
      // }
      if (
        changeCheckboxRef.current === hideOtherPairs &&
        changeInstrumentRef.current === currentInstrument.symbol
      ) {
        fetchData(currentPage);
      } else {
        fetchData(1);
      }
      changeCheckboxRef.current = hideOtherPairs;
      changeInstrumentRef.current = currentInstrument.symbol;
    }
  }, [hideOtherPairs, currentInstrument]);

  useEffect(() => {
    if (listPosition) {
      const newListPosition = listPosition.map((position: any) => {
        const curTradingRule = tradingRulesCache?.filter(
          (item) => item?.symbol === position?.symbol
        );
        const decimalPrice =
          Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;
        const decimalAmount =
          Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;

        const currentTicker = tickers?.find(
          (ticker) => ticker.symbol === position.symbol
        );
        const findPosition = listPositionRef.current?.find(
          (item: IPositionItem) => Number(item.id) === Number(position.id)
        );
        const updatePosition = { ...position };

        const isFindCurTradingRule = curTradingRule.length > 0 ? true : false;

        const newLimitPrice =
          isFindCurTradingRule && currentTicker?.oraclePrice
            ? new BigNumber(currentTicker?.oraclePrice).toFixed(
                decimalPrice,
                BigNumber.ROUND_DOWN
              )
            : "NaN";

        if (findPosition && !isNumber(findPosition.limitPrice)) {
          updatePosition.limitPrice =
            findPosition.limitPrice !== "" ? newLimitPrice : "";
        } else if (findPosition && isNumber(findPosition.limitPrice)) {
          updatePosition.limitPrice = findPosition.limitPrice;
        } else {
          updatePosition.limitPrice = newLimitPrice;
        }

        const newPositionSize = isCoinM
          ? new BigNumber(position.currentQty)
              .abs()
              .toFixed(0, BigNumber.ROUND_DOWN)
          : isFindCurTradingRule
          ? new BigNumber(position.currentQty)
              .abs()
              .toFixed(decimalAmount, BigNumber.ROUND_DOWN)
          : "NaN";

        if (findPosition && !isNumber(findPosition.positionSize)) {
          updatePosition.positionSize =
            findPosition.positionSize !== "" ? newPositionSize : "";
        } else if (findPosition && isNumber(findPosition.positionSize)) {
          const currentPositionSize = isCoinM
            ? new BigNumber(findPosition.currentQty)
                .abs()
                .toFixed(0, BigNumber.ROUND_DOWN)
            : isFindCurTradingRule
            ? new BigNumber(findPosition.currentQty)
                .abs()
                .toFixed(decimalAmount, BigNumber.ROUND_DOWN)
            : "NaN";
          updatePosition.positionSize =
            Number(currentPositionSize) === Number(newPositionSize)
              ? findPosition.positionSize
              : newPositionSize;
        } else {
          updatePosition.positionSize = newPositionSize;
        }

        updatePosition.orders = [];
        const findTakeProfitOrder = allOrders?.find(
          (order) =>
            Number(order.id) === Number(updatePosition?.takeProfitOrderId)
        );
        const findStopLossOrder = allOrders?.find(
          (order) =>
            Number(order.id) === Number(updatePosition?.stopLossOrderId)
        );
        if (findTakeProfitOrder) {
          updatePosition.orders.push(findTakeProfitOrder);
        }
        if (findStopLossOrder) {
          updatePosition.orders.push(findStopLossOrder);
        }

        return updatePosition;
      });
      setRows(newListPosition);
      listPositionRef.current = newListPosition;
    }
  }, [listPosition, account[0]?.id, allOrders, tickers, tradingRulesCache]);

  const handleChangeLimitPrice = (value: string, position: IPositionItem) => {
    const updatePosition = { ...position };
    updatePosition.limitPrice = value;
    const newListPosition = rows.map((row) => {
      return Number(row.id) === Number(position.id) ? updatePosition : row;
    });
    setRows(newListPosition);
    listPositionRef.current = newListPosition;
  };

  const handleChangePositionSize = (value: string, position: IPositionItem) => {
    const updatePosition = { ...position };
    updatePosition.positionSize = value;
    const newListPosition = rows.map((row) => {
      return Number(row.id) === Number(position.id) ? updatePosition : row;
    });
    setRows(newListPosition);
    listPositionRef.current = newListPosition;
  };

  const renderQuantity = (position: IPositionItem) => {
    const curTradingRule = tradingRulesCache?.filter(
      (item) => item.symbol === position?.symbol
    );
    const maxFiguresForSize =
      Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;

    const currentInstrument = instruments?.find(
      (instrument) => instrument.symbol === position.symbol
    );
    return (
      <>
        <TooltipNumber
          defaultValue={Math.abs(Number(position?.currentQty))}
          characters={7}
          decimal={isCoinM ? 0 : maxFiguresForSize}
          symbol={isCoinM ? "Cont" : currentInstrument?.rootSymbol}
        />
      </>
    );
  };

  const renderPrice = (position: IPositionItem, currentPrice: string) => {
    const curTradingRule = tradingRulesCache?.filter(
      (item) => item.symbol === position?.symbol
    );
    const maxFiguresForPrice =
      Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;

    return (
      <TooltipNumber
        characters={11}
        decimal={maxFiguresForPrice}
        defaultValue={currentPrice}
      />
    );
  };

  const renderMargin = (position: IPositionItem, currentMargin: string) => {
    // return <TooltipNumber characters={9} decimal={2} defaultValue={currentMargin} symbol={position?.asset} />;

    return (
      <TextNumber
        defaultValue={Number(currentMargin).toFixed(2)}
        symbol={position?.asset}
        isCoinM={false}
      />
    );
  };

  const renderPNL = (position: IPositionItem, currentPNL: string) => {
    // return (
    //   <TooltipNumber characters={7} decimal={isCoinM ? 5 : 2} defaultValue={currentPNL} symbol={position?.asset} />
    // );

    return (
      <TextNumber
        defaultValue={currentPNL}
        symbol={position?.asset}
        isCoinM={false}
      />
    );
  };

  const renderMarkPrice = (position: IPositionItem) => {
    const currentTicker = tickers?.find(
      (ticker) => ticker.symbol === position.symbol
    );
    const currentInstrument = instruments?.find(
      (instrument) => instrument.symbol === position.symbol
    );
    const decimal = -Math.ceil(
      Math.log10(Number(currentInstrument?.minPriceMovement ?? "0.01"))
    );

    return (
      <TooltipNumber
        characters={11}
        decimal={decimal}
        defaultValue={currentTicker?.oraclePrice}
      />
    );
  };

  const renderLiquidationPrice = (
    position: IPositionItem,
    liquidationPrice: string
  ) => {
    const currentInstrument = instruments?.find(
      (instrument) => instrument.symbol === position.symbol
    );
    const decimal = -Math.ceil(
      Math.log10(Number(currentInstrument?.minPriceMovement ?? "0.01"))
    );

    return (
      <TooltipNumber
        characters={11}
        decimal={decimal}
        defaultValue={liquidationPrice}
      />
    );
  };

  const renderSide = (value: string) => {
    const number = parseFloat(value);
    if (number > 0) {
      return "+";
    } else {
      return "";
    }
  };

  const renderTPSLPrice = (position: IPositionItem, type: string) => {
    const findTakeProfitOrder = position?.orders?.find(
      (order: IOrder) => order.tpSLType === OrderType.takeProfitMarket
    );
    const findStopLossOrder = position?.orders?.find(
      (order: IOrder) => order.tpSLType === OrderType.stopMarket
    );

    const curTradingRule = tradingRulesCache?.filter(
      (item) => item.symbol === position?.symbol
    );
    const maxFiguresForPrice =
      Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;

    if (type === "take-profit") {
      return findTakeProfitOrder ? (
        <TooltipNumber
          characters={7}
          decimal={maxFiguresForPrice}
          defaultValue={findTakeProfitOrder?.tpSLPrice}
        />
      ) : (
        "--"
      );
    } else if (type === "stop-loss") {
      return findStopLossOrder ? (
        <TooltipNumber
          characters={7}
          decimal={maxFiguresForPrice}
          defaultValue={findStopLossOrder?.tpSLPrice}
        />
      ) : (
        "--"
      );
    }
  };

  const handleOpenEntierDialog = (position: IPositionItem) => {
    setOpenEntierPositionDialog(true);
    setCurrentPosition(position);
  };

  const handleCloseEntierDialog = () => {
    setOpenEntierPositionDialog(false);
  };

  const handleClosePosition = async (
    type: OrderType.market | OrderType.limit,
    position: IPositionItem
  ) => {
    const payload: any = {
      positionId: position.id,
      // quantity: Number(position.positionSize),
      quantity: position.positionSize,
      type,
    };

    if (type === OrderType.limit) {
      payload.limitPrice = position.limitPrice;
    }

    try {
      setListPositionId((listId) => {
        const findId = listId?.find((id) => Number(id) === Number(position.id));
        if (!findId) {
          const updateListId = [...listId, Number(position.id)];
          return updateListId;
        }
        return listId;
      });
      const response: any = await axiosInstance.post(
        "/positions/close",
        payload
      );
      setListPositionId((listId) => {
        const filterId = listId?.filter(
          (id) => Number(id) !== Number(position.id)
        );
        return filterId;
      });
      if (response?.code === 200) {
        noti.success({ title: `${t("order.noti.order_submitted")}` });
        dispatch(getPosition({ page: 1, size: RECORDS_PER_PAGE, contractType }));
        dispatch(getAllPosition(contractType));
      }
      if (
        response.status === 400 &&
        response?.data?.info?.message === "Current quantity not enough"
      ) {
        noti.error({ title: `${t("order.noti.invalid_order_quantity")}` });
      }
    } catch (error) {
      console.log(error);
      setListPositionId((listId) => {
        const filterId = listId?.filter(
          (id) => Number(id) !== Number(position.id)
        );
        return filterId;
      });
    }
  };

  const handleOpenDialog = (
    type: OrderType.market | OrderType.limit,
    position: IPositionItem
  ) => {
    setClosePostionData({
      type,
      price: type === OrderType.limit ? position.limitPrice : "",
    });
    setOpenClosePositionDialog(true);
    setCurrentPosition(position);
  };

  const handleCloseDialog = () => {
    setOpenClosePositionDialog(false);
  };

  const handleOpenAllPositonDialog = () => {
    if (!rows.length) return;
    setOpenCloseAllPositionDialog(true);
  };

  const handleCloseAllPositonDialog = () => {
    setOpenCloseAllPositionDialog(false);
  };

  const handleOpenAdjustMarginDialog = (position: IPositionItem) => {
    setOpenAdjustMarginDialog(true);
    setCurrentPosition(position);
  };

  const handleCloseAdjustMarginDialog = () => {
    setOpenAdjustMarginDialog(false);
  };

  const handleCloseWithMarketPrice = (row: IPositionItem) => {
    const curTradingRule = tradingRulesCache?.filter(
      (item) => item?.symbol === row?.symbol
    );
    const decimalAmount =
      Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;

    if (!Number(row.positionSize)) {
      noti.error({ title: `${t("order.noti.blank_size")}` });
      return;
    }
    if (
      new BigNumber(row.positionSize).gt(
        new BigNumber(row.currentQty)
          .abs()
          .toFixed(isCoinM ? 0 : decimalAmount, BigNumber.ROUND_DOWN)
      )
    ) {
      noti.error({ title: `${t("order.noti.invalid_size")}` });
      return;
    }

    if (listPositionId.includes(Number(row.id))) return;

    return userSetting.marketOrder
      ? handleOpenDialog(OrderType.market, row)
      : handleClosePosition(OrderType.market, row);
  };

  const handleCloseWithLimitPrice = (row: IPositionItem) => {
    const currentTicker = tickers?.find(
      (ticker) => ticker?.symbol === row?.symbol
    );
    const curTradingRule = tradingRulesCache?.filter(
      (item) => item?.symbol === row?.symbol
    );
    const decimalPrice =
      Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;
    const decimalAmount =
      Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;
    const floorRatio = Number(curTradingRule[0]?.floorRatio) / 100;
    const capRatio = Number(curTradingRule[0]?.limitOrderPrice) / 100;
    const minPrice = curTradingRule[0]?.minPrice;
    const maxPrice = curTradingRule[0]?.maxPrice;
    let newMinPrice = 0;
    let newMaxPrice = 0;

    if (!Number(row.limitPrice)) {
      noti.error({ title: `${t("order.noti.blank_price")}` });
      return;
    }
    if (!Number(row.positionSize)) {
      noti.error({ title: `${t("order.noti.blank_size")}` });
      return;
    }
    if (
      new BigNumber(row.positionSize).gt(
        new BigNumber(row.currentQty)
          .abs()
          .toFixed(isCoinM ? 0 : decimalAmount, BigNumber.ROUND_DOWN)
      )
    ) {
      noti.error({ title: `${t("order.noti.invalid_size")}` });
      return;
    }

    const positionDirection = getPositionDirection(row);
    const orderDirection =
      positionDirection === "Long" ? OrderSide.sell : OrderSide.buy;

    if (orderDirection === OrderSide.buy) {
      newMinPrice = Number(minPrice);
      newMaxPrice = Math.min(
        Number(maxPrice),
        Number(currentTicker?.oraclePrice) * (1 + capRatio)
      );
    } else {
      newMinPrice = Math.max(
        Number(minPrice),
        Number(currentTicker?.oraclePrice) * (1 - floorRatio)
      );
      newMaxPrice = Number(maxPrice);
    }

    if (Number(row.limitPrice) < newMinPrice) {
      noti.error({
        title: `${t("order.noti.min_order", { number: newMinPrice })}`,
      });
      return;
    } else if (Number(row.limitPrice) > newMaxPrice) {
      noti.error({
        title: `${t("order.noti.max_order", { number: newMaxPrice })}`,
      });
      return;
    }

    if (listPositionId.includes(Number(row.id))) return;

    return userSetting.limitOrder
      ? handleOpenDialog(OrderType.limit, row)
      : handleClosePosition(OrderType.limit, row);
  };
  const handleReversePrice = async (row: IPositionItem) => {
    console.log("🚀 ~ handleReversePrice ~ row:", row);
    let params = {
      side: Number(row.currentQty) > 0 ? "SELL" : "BUY",
      symbol: row.symbol,
      type: "MARKET",
      quantity: Math.abs(Number(row.currentQty)) * 2,
      price: "",
      remaining: Math.abs(Number(row.currentQty)) * 2,
      stopPrice: "",
      stopCondition: "",
      takeProfitCondition: "",
      stopLossCondition: "",
      takeProfit: "",
      stopLoss: "",
      trigger: "ORACLE",
      takeProfitTrigger: "",
      stopLossTrigger: "",
      timeInForce: "GTC",
      isReduceOnly: false,
      callbackRate: "",
      activationPrice: "",
      tpSLType: "",
      tpSLPrice: "",
      isPostOnly: false,
      contractType: row.contractType,
      asset: row.asset,
    };
    const res: any = await axiosInstance.post("/order", params);
    if (res?.code === 200 || res?.code === 201) {
      noti.success({ title: `${t("order.noti.order_submitted")}` });
      // setOpenOrderConfirmModal(false);
      const params = { page: 1, size: RECORDS_PER_PAGE };
      const contractType = location.pathname.includes(TypeTrade.COIN_M)
        ? TypeTrade.COIN_M
        : TypeTrade.USD_M;
      const payload = {
        contractType: contractType,
      };
      dispatch(getPosition({ ...params, symbol: undefined, contractType }));
    }
    if (res.status === 400 || res.status === 404) {
      noti.error({
        title: res.data?.info?.message,
      });
    }
  };

  return (
    <div className={"height-100"}>
      <div className={cx("tbl_data")}>
        {!isAuthenticated && (
          <div className={cx("no-orders")}>
            <div className={cx("warning-table")}>
              <strong onClick={() => handleDirect("login")}>
                {t("order.login")}
              </strong>{" "}
              {t("order.or")}{" "}
              <strong onClick={() => handleDirect("signup")}>
                {t("order.signup")}
              </strong>{" "}
              {t("order.to_start_trading")}
            </div>
          </div>
        )}

        {isAuthenticated && (
          <div className={"height-100"} style={{ position: "relative" }}>
            <div className={cx("table-content")} style={{ minHeight: "450px" }}>
              <div
                className={cx("no-data")}
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "25%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {!loading && !rows.length && (
                  <>
                    <BsInboxesFill
                      fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                      style={{ zoom: 5 }}
                    />
                    <div className={cx("no-data-desc")}>
                      {t("order.no_open_position")}
                    </div>
                  </>
                )}

                {loading && <Loading />}
              </div>

              <table className={cx("dex_table", "text-table-xs")}>
                <thead>
                  <tr className={cx("table-head")}>
                    <th style={{ minWidth: "120px", width: "10%" }}>
                      {t("order.symbol")}
                    </th>
                    <th style={{ minWidth: "115px", width: "8%" }}>
                      <TooltipText
                        text={t("order.tooltip.size", {
                          name: isCoinM ? "COIN-M" : "USDⓈ-M",
                          asset: isCoinM ? "BTC" : "USDT",
                          symbol: isCoinM ? t("order.cont") : t("order.coin"),
                        })}
                      >
                        <span>{t("order.size")}</span>
                      </TooltipText>
                    </th>
                    <th style={{ minWidth: "85px", width: "7%" }}>
                      <TooltipText text={t("order.tooltip.entry_price")}>
                        <span>{t("order.entry_price")}</span>
                      </TooltipText>
                    </th>
                    <th style={{ minWidth: "85px", width: "7%" }}>
                      <TooltipText
                        text={t("order.tooltip_mark_price", {
                          number: formatPrice(
                            ticker?.indexPrice,
                            currentInstrument
                          ),
                        })}
                      >
                        <span>{t("order.mark_price")}</span>
                      </TooltipText>
                    </th>
                    <th style={{ minWidth: "85px", width: "7%" }}>
                      <TooltipText text={t("order.tooltip.liq_price")}>
                        <span>{t("order.liq_price")}</span>
                      </TooltipText>
                    </th>
                    <th style={{ minWidth: "100px", width: "8%" }}>
                      <TooltipText text={t("order.tooltip_margin_ratio")}>
                        <span>{t("order.margin_ratio")}</span>
                      </TooltipText>
                    </th>
                    <th style={{ minWidth: "120px", width: "10%" }}>
                      <TooltipText text={t("order.tooltip.margin")}>
                        <span>{t("order.margin")}</span>
                      </TooltipText>
                    </th>
                    <th style={{ minWidth: "120px", width: "9%" }}>
                      <TooltipText text={t("order.tooltip.pnl")}>
                        <span>{t("order.pnl")}</span>
                      </TooltipText>
                    </th>
                    <th
                      onClick={handleOpenAllPositonDialog}
                      style={{
                        color: "#00DDB3",
                        minWidth: "280px",
                        width: "21%",
                      }}
                    >
                      {!isMobile && !isTablet ? (
                        <TooltipText
                          text={t("order.tooltip.close_all_position", {
                            name: isCoinM ? "COIN-M" : "USDⓈ-M",
                          })}
                        >
                          <span style={{ cursor: "pointer" }}>
                            {t("order.close_all_position")}
                          </span>
                        </TooltipText>
                      ) : (
                        <span style={{ cursor: "pointer" }}>
                          {t("order.close_all_position")}
                        </span>
                      )}
                    </th>
                    <th
                      style={{
                        minWidth: "80px",
                        width: "8%",
                        paddingRight: "0px",
                      }}
                    >
                      <TooltipText text={t("order.tooltip_reverse")}>
                        <div>{t("order.reverse")}</div>
                      </TooltipText>
                    </th>
                    <th
                      style={{
                        minWidth: "85px",
                        width: "10%",
                        paddingRight: "0px",
                      }}
                    >
                      <TooltipText text={t("order.tooltip_tpls_for_position")}>
                        <span>{t("order.tpls_for_position")}</span>
                      </TooltipText>
                    </th>
                  </tr>
                </thead>
                <tbody
                  className={cx("dex_table__scrollbar", "overflow-tbody")}
                  style={{ maxHeight: 210, verticalAlign: "top" }}
                >
                  {loading && <div style={{ height: 230 }}></div>}
                  {!loading && !rows.length && (
                    <div style={{ height: 230 }}></div>
                  )}

                  {!loading && !!rows.length && (
                    <>
                      {rows.map((row, index) => (
                        <tr key={index}>
                          <td
                            className={cx("column-symbol")}
                            onClick={() => {
                              if (currentInstrument.symbol === row.symbol)
                                return;
                              window.location.replace(
                                `/futures/${row?.contractType}/${row.symbol}`
                              );
                            }}
                          >
                            <div
                              className={cx(
                                "column-color",
                                getValueClassName(row.currentQty)
                              )}
                            ></div>
                            <div>
                              <div>
                                <span className={cx("text-coin")}>
                                  {getSymbolName(row.symbol)}
                                </span>
                                <span className={cx("text-leverage")}>
                                  <b>
                                    {(row.leverage &&
                                      Number(row.leverage)
                                        .toFixed()
                                        .concat("x")) ||
                                      "-"}
                                  </b>
                                </span>
                              </div>
                              <div className={cx("text-perpetual")}>
                                {t("order.perpetual")}
                              </div>
                            </div>
                          </td>
                          <td className={cx(getValueClassName(row.currentQty))}>
                            {renderQuantity(row)}
                          </td>
                          <td className={cx("text-value")}>
                            {renderPrice(row, row.entryPrice)}
                          </td>
                          <td className={cx("text-value")}>
                            {renderMarkPrice(row)}
                          </td>
                          <td className={cx("text-liq")}>
                            {Number(
                              PositionCalculator.getLiquidationPrice(
                                row,
                                account,
                                allPosition,
                                row.adjustMargin
                              )
                            )
                              ? renderLiquidationPrice(
                                  row,
                                  PositionCalculator.getLiquidationPrice(
                                    row,
                                    account,
                                    allPosition,
                                    row.adjustMargin
                                  )
                                )
                              : "--"}
                          </td>
                          <td className={cx("text-value")}>
                            {formatPercent(
                              PositionCalculator.getMarginRatio(row),
                              DECIMAL_TWO
                            )}
                          </td>
                          <td>
                            <Box display="flex" alignItems="center">
                              <span className={cx("text-value")}>
                                {renderMargin(
                                  row,
                                  PositionCalculator.getAllocatedMargin(row)
                                )}
                              </span>
                              {!row.isCross && (
                                <BsFillPencilFill
                                  fill={
                                    theme === THEME_MODE.LIGHT
                                      ? "black"
                                      : "white"
                                  }
                                  onClick={() =>
                                    handleOpenAdjustMarginDialog(row)
                                  }
                                />
                              )}
                            </Box>
                            <div className={cx("text-margin")}>
                              {row.isCross
                                ? `(${t("order.cross")})`
                                : `(${t("order.isolated")})`}
                            </div>
                          </td>
                          <td>
                            <div
                              className={cx(
                                getValueClassName(
                                  PositionCalculator.getUnrealizedPNL(row)
                                ) || "text-value",
                                "flex"
                              )}
                            >
                              {renderSide(
                                PositionCalculator.getUnrealizedPNL(row)
                              )}
                              {renderPNL(
                                row,
                                Number(PositionCalculator.getUnrealizedPNL(row)).toFixed(2)
                              )}
                            </div>
                            <div
                              className={cx(
                                getValueClassName(
                                  PositionCalculator.getUnrealizedROE(row)
                                ) || "text-value"
                              )}
                            >
                              {`(${renderSide(
                                PositionCalculator.getUnrealizedROE(row)
                              )}${formatPercent(
                                PositionCalculator.getUnrealizedROE(row),
                                DECIMAL_TWO
                              )})`}
                            </div>
                          </td>
                          <td>
                            <Box display="flex" alignItems="center">
                              <div>
                                <button
                                  className={cx("text-position")}
                                  onClick={() =>
                                    handleCloseWithMarketPrice(row)
                                  }
                                >
                                  {t("order.market")}
                                </button>{" "}
                                |{" "}
                                <button
                                  className={cx("text-position")}
                                  onClick={() => handleCloseWithLimitPrice(row)}
                                >
                                  {t("order.limit")}
                                </button>
                              </div>
                              <InputNumber
                                defaultValue={row.limitPrice}
                                position={row}
                                type="price"
                                onChange={(
                                  value: string,
                                  position: IPositionItem
                                ) => handleChangeLimitPrice(value, position)}
                              />
                              <InputNumber
                                defaultValue={row.positionSize}
                                position={row}
                                type="quantity"
                                onChange={(
                                  value: string,
                                  position: IPositionItem
                                ) => handleChangePositionSize(value, position)}
                              />
                            </Box>
                          </td>
                          <td>
                            <div
                              className={cx("text-reverse")}
                              onClick={() => handleReversePrice(row)}
                            >
                              {t("order.reverse")}
                            </div>
                          </td>
                          <td>
                            <Box display="flex" alignItems="center">
                              <span className={cx("text-tpsl")}>
                                {renderTPSLPrice(row, "take-profit")}
                              </span>
                              <div className={cx("text-tpsl")}>/</div>
                              <BsFillPencilFill
                                fill={
                                  theme === THEME_MODE.LIGHT ? "black" : "white"
                                }
                                onClick={() => handleOpenEntierDialog(row)}
                              />
                            </Box>
                            <span className={cx("text-tpsl")}>
                              {renderTPSLPrice(row, "stop-loss")}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>

              {!loading && totalPage > 1 && (
                <div className={cx("pagination")}>
                  <Pagination
                    page={currentPage}
                    onChange={(e, page) => {
                      fetchData(page);
                    }}
                    count={totalPage}
                    variant="outlined"
                    shape="rounded"
                    className={cx("pagination-custom")}
                    sx={{
                      "& .MuiPaginationItem-root": {
                        color: "var(--color-value-1) !important",
                      },
                      "& .Mui-selected": {
                        backgroundColor:
                          "var(--color-background-pagination) !important",
                        border:
                          "1px solid var(--color-outline-pagination) !important",
                        color: "var(--color-text-pagination) !important",
                        borderRadius: "5px !important",
                      },
                      "& .MuiPagination-ul": {
                        flexWrap: "nowrap",
                      },
                    }}
                  />
                </div>
              )}
            </div>
            <div>
              <ClosePositionDialog
                closePostionData={closePostionData}
                data={currentPosition}
                handleCloseDialog={handleCloseDialog}
                handleConfirm={handleClosePosition}
                openDialog={openClosePositionDialog}
              />
              <CloseAllPosition
                handleCloseDialog={handleCloseAllPositonDialog}
                openDialog={openCloseAllPositionDialog}
              />
              <EntierPositionDialog
                data={currentPosition}
                handleCloseDialog={handleCloseEntierDialog}
                openDialog={openEntierPositionDialog}
                listPosition={rows}
              />
              <AdjustMarginDialog
                data={currentPosition}
                handleCloseDialog={handleCloseAdjustMarginDialog}
                openDialog={openAdjustMarginDialog}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PositionRecord;
